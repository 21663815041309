jQuery(document).ready(function($) {
	/* Submission form content box styling */
	// Set labels as placeholders
	$(".homepage-section #submissionforms_module.homepage_contentbox form > table > tbody > tr > td > input[type='text'], .homepage-section #submissionforms_module.homepage_contentbox form > table > tbody > tr > td > textarea, .homepage-section #submissionforms_module.homepage_contentbox form > table > tbody > tr > td > input[type='email']").each(function(index, elem) {
		var eId = $(elem).attr("id");
		var label = null;
		if (eId && (label = $(elem).parents("form").find("label[for='" + eId + "']")).length == 1) {
			$(elem).attr("placeholder", $(label).html());
			$(label).parent().parent().hide();
		}
	});

	$(".homepage-section #submissionforms_module.homepage_contentbox form > table > tbody > tr > td > select").each(function(index, elem) {
		var eId = $(elem).attr("id");
		var label = null;
		if (eId && (label = $(elem).parents("form").find("label[for='" + eId + "']")).length == 1) {
			if ($(elem).find('option:nth-child(1)').text() == "" && $(elem).find('option:nth-child(1)').val() == "") {
				$(elem).find('option:nth-child(1)').text($(label).html());
			}
			$(label).parent().parent().hide();
		}
	});
});
